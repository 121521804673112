exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-llama-software-404-js": () => import("./../../../src/pages/llama-software/404.js" /* webpackChunkName: "component---src-pages-llama-software-404-js" */),
  "component---src-pages-llama-software-chat-system-comparison-js": () => import("./../../../src/pages/llama-software/chat-system-comparison.js" /* webpackChunkName: "component---src-pages-llama-software-chat-system-comparison-js" */),
  "component---src-pages-llama-software-news-js": () => import("./../../../src/pages/llama-software/news.js" /* webpackChunkName: "component---src-pages-llama-software-news-js" */),
  "component---src-pages-llama-software-privacy-policy-js": () => import("./../../../src/pages/llama-software/privacy-policy.js" /* webpackChunkName: "component---src-pages-llama-software-privacy-policy-js" */),
  "component---src-pages-llama-software-products-js": () => import("./../../../src/pages/llama-software/products.js" /* webpackChunkName: "component---src-pages-llama-software-products-js" */),
  "component---src-pages-llama-survival-404-js": () => import("./../../../src/pages/llama-survival/404.js" /* webpackChunkName: "component---src-pages-llama-survival-404-js" */),
  "component---src-pages-llama-survival-media-js": () => import("./../../../src/pages/llama-survival/media.js" /* webpackChunkName: "component---src-pages-llama-survival-media-js" */),
  "component---src-pages-llama-survival-news-js": () => import("./../../../src/pages/llama-survival/news.js" /* webpackChunkName: "component---src-pages-llama-survival-news-js" */),
  "component---src-templates-news-template-js": () => import("./../../../src/templates/news-template.js" /* webpackChunkName: "component---src-templates-news-template-js" */)
}

